<template>
    <div>
      <Datatable ref="datatable" route="/client/message/liste/all" :fields="fields" :data_filters="filters" titre="Liste des messages" :sort="sort">      
        <template #action>                  
        </template>
        <template #cell(client.nom)="data">
          <router-link :to="'/prospect/detail/'+data.item.client.id">{{data.value}} {{ data.item.client.prenom }}</router-link>
        </template>        
        <template #cell(action)="data">
            <Message :client_id="data.item.client.id" :phones="[data.item.client.telephone_1, data.item.client.telephone_2]" :obj="data.item.client" type="Client" class="mr-1"/>
        </template>    
        <template #cell(_id.to)="data">
            <Telephone v-model="data.value" :edit="false" />
        </template>          
      </Datatable>      
    </div>
  </template>
  <script>
  import Datatable from '@/components/Datatable';
  import Telephone from '@/components/Telephone';
  import Message from '@/components/Message';
  export default {
    name: "ClientListe",
    components: {
      Datatable,Telephone, Message
    },
    watch:{    
    },
    data(){
      return{
        filters:{},
        fields: [
          {
            key: 'client.nom',
            label: 'Client',
            sortable:false,
          },
          {
            key: '_id.to',
            label: 'Numero',
            sortable:true,
          },
          {
            key: 'nb',
            label: 'Nb messages',
            sortable:false,
          },      
          {
            key: 'action',
            label: 'Action',
            sortable:false,
          }          
        ],      
        sort:{
          key: 'id',
          value: 'DESC'
        },
        loadMail:false,
        tplMail: null
      }
    },
    computed:{      
    },
    methods: {     
    },
    beforeMount() {
    },
    mounted() {        
    }
  }
  </script>
  <style>
  @media print {
    .menu-desktop, #menu-mobile, #header{
      display: none !important;
    }            
  }
  </style>
  